import get from 'lodash/get';
import { getConfig } from '../config/portal';
import { getAssetUrl } from './commonHelper';
import { getMessages } from '../tppServices/translations/messages';

export const getEnabledFacets = () => {
  return get(getConfig(), 'pages.partySearch.facets', []).map(
    (facet) => facet.name
  );
};

export const getPartySearchFormImage = () => {
  return get(getConfig(), 'pages.partySearch.backgroundFormImage', '');
};

export const getPartySearchRadii = () => {
  const radiusConfig = getConfig().pages.partySearch.facets.find(
    (facet) => facet.name === 'distance'
  );
  return get(radiusConfig, 'radii', []);
};

export const getPartySearchDefaultRadius = () => {
  const radiusConfig = getConfig().pages.partySearch.facets.find(
    (facet) => facet.name === 'distance'
  );
  return get(radiusConfig, 'defaultRadius', '');
};

export const getPartySearchPageSize = () => {
  return getConfig().pages.partySearch.pagination.pageSize;
};

export const getPartySearchPageHorizon = () => {
  return getConfig().pages.partySearch.pagination.pageHorizon;
};

export const getPartySearchSortOptions = () => {
  return getConfig().pages.partySearch.sort.options;
};

export const getPartySearchSortDefaultValue = () => {
  return getConfig().pages.partySearch.sort.default;
};

export const getFilterRegionSortDefaultValue = () => {
  return getConfig().pages.partySearch.quickFilterRegion.defaultSort;
};

export const getFilterRegionOrder = () => {
  return getConfig().pages.partySearch.quickFilterRegion.order;
};

export const getPartiesHelperDefaults = () => ({
  pageSize: getPartySearchPageSize(),
  page: 1,
  sort: getPartySearchSortDefaultValue()
});

export const partiesSortNameMap = () => {
  const messages = getMessages();
  const SORT_NAME_MAP = {
    'proximity-asc': messages.searchHeader.sort.nearest,
    'proximity-desc': messages.searchHeader.sort.farthest,
    'displayName-asc': messages.searchHeader.sort.nameAsc,
    'displayName-desc': messages.searchHeader.sort.nameDesc
  };
  return SORT_NAME_MAP;
};

export const getPartySearchValidParams = () => {
  return getConfig().pages.partySearch.params;
};

export const getPartySearchTrackingMapping = (type, value) => {
  const sortMapping = get(
    getConfig(),
    `pages.partySearch.analytics.mapping.${type}`,
    []
  );
  const entry = sortMapping.find(({ key }) => key === value);
  return get(entry, 'value');
};

export const getBannerImage = (partyBannerPath) => {
  const conf = getConfig();
  const defaultPortalBanner = conf.pages.partySearch.defaultBanner;

  if (partyBannerPath) {
    return partyBannerPath;
  }

  return getAssetUrl(defaultPortalBanner);
};

export const getPartyDetailsLogo = (partyDetails) => {
  return partyDetails?.logoPath || partyDetails?.logoUrl || '';
};
