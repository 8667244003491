import React from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import { getRedirectsUrlPattern } from '../utils/urlHelpers/redirects';
import {useTPPServices} from '../tppServices/tppDIHooks';
import { getCurrentLocale } from '../utils/language';

/* React Loadable Routes */

const LoadableHome = loadable(() => import('../pages/Home'));
const LoadableSearchResults = loadable(() => import('../pages/SearchResults'));
const LoadableEngineSearchResults = loadable(() =>
  import('../pages/SearchResults/Engines')
);
const LoadableBoatDetails = loadable(() => import('../pages/Details'));
const LoadableEngineDetails = loadable(() => import('../pages/Details/Engine'));
const LoadableHealthCheck = loadable(() => import('../pages/HealthCheck'));
const LoadablePartySearch = loadable(() => import('../pages/PartySearch'));
const LoadableStaticContent = loadable(() => import('../pages/StaticContent'));
const LoadableBrandedSearch = loadable(() => import('../pages/BrandedSearch'));
const LoadableEnginesBrandedSearch = loadable(() =>
  import('../pages/BrandedSearch/Engines')
);
const LoadableRedirects = loadable(() => import('../pages/Redirects'));
const LoadableBlog = loadable(() => import('../pages/Blog'));
const LoadableBoatLoans = loadable(() => import('../pages/BoatLoans'));
const LoadableYachtLoansCalculator = loadable(() =>
  import('../pages/BoatLoansCalculator')
);
const LoadableBrandedOemSearchResults = loadable(() =>
  import('../pages/BrandedOemSearchResults')
);
const LoadableSocialBrowse = loadable(() => import('../pages/SocialBrowse'));
const LoadableTypeClassPages = loadable(() => import('../pages/TypeClass/Type'));
const LoadableClassPage = loadable(() => import('../pages/TypeClass/Class'));
const LoadableMooringsSearch = loadable(() => import('../pages/MooringsSearch'));


/* eslint-disable react/display-name */
export default () => {
  const {currentI18n} = useTPPServices();
  let routes = currentI18n.routesConstants;
  let boats = currentI18n.boatsConstants;
  const REDIRECTS_URL_PATTERN = getRedirectsUrlPattern();
  // Check for unexpected characters and redirect to "/" if necessary
  const selectedLocale = getCurrentLocale(true);
  const isUS = selectedLocale === 'en-US';
  return (
    <Switch>
      {/* Routes are dynamic depending on the language of the url */}
      <Route exact path={routes.HOME_URL_PATTERN} component={LoadableHome} />
      <Route
        path={boats.SEARCH_URL_PATTERN}
        exact={true}
        component={LoadableSearchResults}
      />
      <Route
        path={boats.ENGINES_SEARCH_URL_PATTERN}
        exact={true}
        component={LoadableEngineSearchResults}
      />
      <Route
        path={routes.BOAT_DETAILS_URL_PATTERN}
        component={LoadableBoatDetails}
      />
      <Route
        path={routes.ENGINE_DETAILS_URL_PATTERN}
        component={LoadableEngineDetails}
      />
      <Route
        path={routes.HEALTHCHECK_URL_PATTERN}
        component={LoadableHealthCheck}
      />
      <Route
        path={routes.PARTY_SEARCH_URL_ROOT}
        exact={true}
        component={LoadablePartySearch}
      />
      <Route
        path={routes.SOCIAL_BROWSE_URL_ROOT}
        exact={true}
        component={LoadableSocialBrowse}
      />
      <Route
        path={boats.DEALER_GALLERY_URL_PATTERN}
        exact={true}
        component={LoadableBrandedSearch}
      />
      <Route
        path={boats.BRANDED_SEARCH_URL_PATTERN}
        exact={true}
        component={LoadableBrandedSearch}
      />
      <Route
        path={boats.BRANDED_OEM_SEARCH_URL_PATTERN}
        exact={true}
        component={LoadableBrandedOemSearchResults}
      />
      <Route
        path={boats.ENGINES_BRANDED_SEARCH_URL_PATTERN}
        exact={true}
        component={LoadableEnginesBrandedSearch}
      />
      <Route
        path={boats.ENGINES_DEALER_GALLERY_URL_PATTERN}
        exact={true}
        component={LoadableEnginesBrandedSearch}
      />
      <Route
        path={REDIRECTS_URL_PATTERN}
        exact={true}
        component={LoadableRedirects}
      />
      <Route
        exact
        path={routes.TYPE_CLASS_PAGES_ROOT}
        component={LoadableTypeClassPages}
      />
      <Route
        exact
        path={routes.CLASS_PAGE_URL_PATTERN}
        component={LoadableClassPage}
      />
      <Route
        path={boats.BLOG_URL_PATTERN}
        exact={true}
        component={LoadableBlog}
      />
      <Route
        path={boats.MOORINGS_SEARCH_URL_PATTERN}
        exact={true}
        component={LoadableMooringsSearch}
      />
      {isUS &&
          <Route
            exact
            path={routes.BOAT_LOANS_URL_ROOT}
            component={LoadableBoatLoans}
          />
      }
      {isUS &&
          <Route
            exact
            path={routes.YACHT_LOANS_CALCULATOR_URL_ROOT}
            component={LoadableYachtLoansCalculator}
          />
      }
      <Route path="*" component={LoadableStaticContent} />
    </Switch>
  );
};
