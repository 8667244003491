import indexOf from 'lodash/indexOf';
import { FEATURE_FLAG_KEYS, isFeatureFlagActive } from '../../context/ABTestContext';
import { getIpAddress } from '../../server/middleware/services';
import { hasAdvertisingConsent } from '../cookies';
import { getBoatConstantsFromI18n } from '../../tppServices/translations/constants';
import { getCustomUom } from '../uomHelper';
import { getCurrentLocale } from '../language';
import { parseRangesFromUrl } from '../parsers/listingParsers';
import { formatMultiFacetParam } from '../multiFacetHelper';
import { cleanObject } from '../index';
import { detectDevice, DEVICES, logObjects, logOnserver } from '../../tppServices/crossEnvHelpers';

const AD_PAGE_KEY = {
  HOME: 'home',
  DETAILS: 'details',
  SEARCH_RESULTS: 'searchResults',
  SEARCH_RESULTS_ONE_COLUMN: 'searchResultsOneColumn',
  SBP: 'socialBrowse',
  BRANDED_OEM_SEARCH_RESULTS: 'brandedOemSearchResults',
  BLOG: 'blog',
};

const DEFAULT_AD_ALT = 'img';
const getDefaultAdHref = () => {
  const locale = getCurrentLocale();
  return locale === 'us'
    ? 'https://www.boatsgroup.com/advantage/'
    : 'https://www.boatsgroup.com/subscriptions-eu/';
};

const lengthRanges = [
  { rangeLabel: "1_17_ft", rangeLow: 1, rangeHigh: 17 },
  { rangeLabel: "18_28_ft", rangeLow: 18, rangeHigh: 28 },
  { rangeLabel: "29_37_ft", rangeLow: 29, rangeHigh: 37 },
  { rangeLabel: "38_44_ft", rangeLow: 38, rangeHigh: 44 },
  { rangeLabel: "45_55_ft", rangeLow: 45, rangeHigh: 55 },
  { rangeLabel: "56_72_ft", rangeLow: 56, rangeHigh: 72 },
  { rangeLabel: "73_85_ft", rangeLow: 73, rangeHigh: 85 },
  { rangeLabel: "86_92_ft", rangeLow: 86, rangeHigh: 92 },
  { rangeLabel: "93_115_ft", rangeLow: 93, rangeHigh: 115 },
  { rangeLabel: "116_plus_ft", rangeLow: 116, rangeHigh: null }
];

const yearRanges = [
  { rangeLabel: "1900_1940", rangeLow: 1900, rangeHigh: 1940 },
  { rangeLabel: "1941_1960", rangeLow: 1941, rangeHigh: 1960 },
  { rangeLabel: "1961_1975", rangeLow: 1961, rangeHigh: 1975 },
  { rangeLabel: "1976_1990", rangeLow: 1976, rangeHigh: 1990 },
  { rangeLabel: "1991_2000", rangeLow: 1991, rangeHigh: 2000 },
  { rangeLabel: "2001_2005", rangeLow: 2001, rangeHigh: 2005 },
  { rangeLabel: "2006_2010", rangeLow: 2006, rangeHigh: 2010 },
  { rangeLabel: "2011_2015", rangeLow: 2011, rangeHigh: 2015 },
  { rangeLabel: "2016_plus", rangeLow: 2016, rangeHigh: null }
];

const canonicalizeDataString = (param) => {
  let canonical;
  if (param) {
    canonical = decodeURIComponent(param);
    canonical = canonical.toLowerCase();
    canonical = canonical.replace('amp;', '').replace(/\W/g, '');
  }
  return canonical;
};

const _validConditions = ['new', 'used'];
const isValidCondition = (condition) => {
  return condition && indexOf(_validConditions, condition) !== -1;
};

const getPageAdsData = (config, adsData, cdnUrl) => {
  const adZones = Object.keys(config).reduce((acc, key) => {
    const zoneID = config[key].zoneID;
    acc[key] = { ...config[key] };
    if (acc[key].defaultImageURL) {
      acc[key].defaultImageURL = `${cdnUrl}${acc[key].defaultImageURL}`;
    }

    if (adsData && Array.isArray(adsData[zoneID]?.placements)) {
      acc[key].placement = adsData[zoneID].placements;
    } else if (adsData && adsData[zoneID]?.placements?.placement_1) {
      acc[key].placement = adsData[zoneID].placements.placement_1;
    }
    return acc;
  }, {});
  return adZonesByDevice(adZones, detectDevice());
};

const adZonesByDevice = (adZones, device) => {
  try {
    // if we can't detect device, device is a tablet, or empty, return all adZones
    if (device === DEVICES.unknown || device === DEVICES.tablet || !device) {
      return adZones;
    }
    const newZones = Object.keys(adZones).reduce((acc, key) => {
      const devices = adZones[key].devices;
      const isDeviceSupported = !devices || devices.includes(device) || devices.includes(DEVICES.all);
      if (isDeviceSupported) {
        acc[key] = adZones[key];
      }
      return acc;
    }, {});
    return newZones;
  } catch (e) {
    /* eslint-disable no-console */
    console.error('error filtering adZones', e);
    return adZones;
  }
};

const multiAdsPayload = (cfg, abTestContext, forceAdButlerAds, cookies, customAdParams, queryParams, dealer, listing, pageKey) => {
  const supportsAdButler = !!cfg?.supports?.adButler;
  const featureFlagAdButler = (isFeatureFlagActive(
    FEATURE_FLAG_KEYS.AD_BUTLER,
    abTestContext?.featureFlags,
    cookies
  ) || forceAdButlerAds) && supportsAdButler;

  const adsConfig = cfg?.pages?.[pageKey]?.newAdsConfig;

  if (!featureFlagAdButler || !pageKey || !adsConfig) {
    const logOrigin = 'multiAdsPayload';
    logObjects({featureFlagAdButler, pageKey, adsConfig, logOrigin});
    return null;
  }

  const targetingUom = getTargetingUom(cfg, cookies);
  // eslint-disable-next-line camelcase
  adsConfig._abdk_json = getTargeting(queryParams, targetingUom, dealer, listing, cfg.name, false, pageKey);
  const ipAddress = getIpAddress();
  const device = detectDevice();
  const filteredZones = adZonesByDevice(adsConfig.adZones, device);
  const adZones = filteredZones;
  const body = {
    adsPerZone: adsConfig.adsPerZone || 'one',
    ...adsConfig.kwArray && { kw: adsConfig.kwArray },
    ...adsConfig._abdk_json && { '_abdk_json': adsConfig._abdk_json },
    hasTargetedAdsConsent: hasAdvertisingConsent(cookies || {}),
    zoneIDs: Object.values(adZones).map(ad => ad.zoneID),
    ...adsConfig.roadblocks && {
      groupIDs: Object.values(adZones).reduce((acc, ad) => {
        acc[ad.zoneID] = ad.groupID;
        return acc;
      }, {})
    },
    ...ipAddress && { ip: ipAddress },
    ...customAdParams?.testIP && { testIP: customAdParams.testIP },
    ...(adsConfig.adsPerZone === 'all' && adsConfig.limitOfAds && { limitOfAds: adsConfig.limitOfAds }),
    ...customAdParams?.testId && { testId: customAdParams.testId }
  };

  logOnserver(JSON.stringify({...body, tag: 'serveMultiAds'}), !!customAdParams?.testId);
  return body;
};

const getInPageAdsConfig = (pageConfig, pageKey, adsData, abTestContext, cookies) => {
  const supportsAdButler = !!pageConfig?.supports?.adButler;
  const featureFlagAdButler = isFeatureFlagActive(
    FEATURE_FLAG_KEYS.AD_BUTLER,
    abTestContext?.featureFlags,
    cookies
  ) && supportsAdButler;

  if (!featureFlagAdButler) {
    return null;
  }
  const pageContext = pageConfig?.pages[pageKey];
  const adZones = pageContext?.newAdsConfig?.adZones;

  if (!adZones) {
    return null;
  }
  const cdnUrl = pageConfig?.client?.cdn?.endpoint;
  const pageAdsData = getPageAdsData(adZones, adsData, cdnUrl);
  return pageAdsData;
};

const getTargeting = (query, uom, dealer, listing, site, reloadAd, pageKey) => {
  if (pageKey === AD_PAGE_KEY.HOME) {
    return getHomePageTargeting(site);
  }

  if (pageKey === AD_PAGE_KEY.SBP) {
    return getSocialBrowsePageTargeting(site);
  }

  if (pageKey === AD_PAGE_KEY.SEARCH_RESULTS || pageKey === AD_PAGE_KEY.BRANDED_OEM_SEARCH_RESULTS || pageKey === AD_PAGE_KEY.SEARCH_RESULTS_ONE_COLUMN) {
    return getSearchResultsPageTargeting(query, uom, dealer);
  }

  if (pageKey === AD_PAGE_KEY.DETAILS) {
    return getBoatDetailsPageTargeting(listing, uom);
  }

  return null;
};

const getHomePageTargeting = (site) => {
  return {
    page: AD_PAGE_KEY.HOME,
    site,
  };
};

const getSocialBrowsePageTargeting = (site) => {
  return {
    page: AD_PAGE_KEY.SBP,
    site,
  };
};

const getSearchResultsPageTargeting = (query, uom, dealer) => {
  query.length = parseRangesFromUrl(query?.length);
  query.year = parseRangesFromUrl(query?.year);
  query.multiFacetedBoatTypeClass = formatMultiFacetParam(query?.multiFacetedBoatTypeClass);
  query.makeModel = formatMultiFacetParam(query?.multiFacetedMakeModel);
  const boatConstants = getBoatConstantsFromI18n();
  const lengthMin = query.length && query.length.min !== '0' ? query.length?.min : undefined;
  const lengthMax = query.length?.max;
  const yearMin = query.year?.min !== boatConstants.DEFAULT_MIN_YEAR || query.year?.max ? query.year?.min : undefined;
  const yearMax = query.year?.max;

  let boatClass;
  let type;
  if (query.multiFacetedBoatTypeClass && Object.keys(query.multiFacetedBoatTypeClass).length > 0) {
    boatClass = query.multiFacetedBoatTypeClass[
      Object.keys(query.multiFacetedBoatTypeClass)[0]
      ][1];
    type = canonicalizeDataString(query.multiFacetedBoatTypeClass[
      Object.keys(query.multiFacetedBoatTypeClass)[0]
      ][0]);
  }
  const targeting = {
    type: type,
    class: boatClass,
    condition: isValidCondition(query.condition) ? query.condition : undefined,
    length: getLengthRanges(lengthMin, lengthMax, uom),
    year: getYearRanges(yearMin, yearMax),
    make: query.makeModel ? canonicalizeDataString(query?.makeModel?.[0]) : undefined,
    state: query.subdivision || undefined,
    page: 'boat-search',
    ...(dealer && {
      'dealer_id': dealer.id,
      dealercity: dealer.dealerLocation?.city,
      dealercountry: dealer.dealerLocation?.country,
    }),
  };

  return cleanObject(targeting);
};

const getBoatDetailsPageTargeting = (listing, uom) => {
  const length = listing?.specifications?.dimensions?.lengths?.nominal?.[uom];
  const state = listing?.location?.address?.subdivision;
  const zip = listing?.location?.address?.postalCode;
  const boatCity = listing?.location?.address?.city;
  const boatCountry = listing?.location?.address?.country;
  const ownerId = listing?.owner?.id;
  const dealerCity = listing?.owner?.location?.address?.city;
  const dealerCountry = listing?.owner?.location?.address?.country;

  const targeting = {
    type: canonicalizeDataString(listing?.type),
    class: listing?.class,
    condition: isValidCondition(listing?.condition) ? listing.condition : undefined,
    length: getLengthRanges(length, length, uom),
    year: getYearRanges(listing?.year, listing?.year),
    make: listing?.make ? canonicalizeDataString(listing.make) : undefined,
    'dealer_id': ownerId ? String(ownerId) : undefined,
    dealercountry: dealerCountry,
    dealercity: dealerCity,
    boatcountry: boatCountry,
    boatcity: boatCity,
    state: state || undefined,
    'zip_code': zip || undefined,
    page: 'boat-details',
    modelyear: listing?.year ? String(listing.year) : undefined,
  };

  return cleanObject(targeting);
};

const getTargetingUom = (cfg, cookies) => {
  const isDefaultLocaleUom = cfg?.supports?.defaultUoms;
  const supportedUom = cfg?.supportedUoms;
  const defaultLocaleUom = cfg?.languages?.[getCurrentLocale(true)]?.defaultUom;
  const customUom = getCustomUom(
    isDefaultLocaleUom,
    cookies,
    supportedUom,
    defaultLocaleUom
  );
  return customUom || cfg?.uom?.length?.abbr;
};

const isValidValue = (value) => {
  return  (typeof value === "number" || typeof value === "string") && value !== "";
};

const getRanges = (rangeType, min, max) => {
  if (!isValidValue(min) && !isValidValue(max)) {return undefined;}

  const validMin = Math.floor(min);
  const validMax = Math.floor(max);

  return rangeType
    .filter(({ rangeLow, rangeHigh }) =>
      (validMin === undefined || validMin <= (rangeHigh ?? Infinity)) &&
      (validMax === undefined || validMax >= rangeLow)
    )
    .map(({ rangeLabel }) => rangeLabel)
    .join(",");
};

const getLengthRanges = (min, max, uom = 'ft') => {
  if (uom === "m") {
    min = isValidValue(min) ? min * 3.28084 : min;
    max = isValidValue(max) ? max * 3.28084 : max;
  }
  return getRanges(lengthRanges, min, max);
};

const getYearRanges = (min, max) => {
  return getRanges(yearRanges, min, max);
};

const getDefaultAdAlt = (text) => {
  return text || DEFAULT_AD_ALT;
};

const getPlacement = ({ placement, pageAdReload, enableAdRefresh = false }) => {
  if (!Array.isArray(placement)) {
    return {
      adPlacement: placement,
      isNativeAd: !!placement?.body?.trim()
    };
  }

  let adPlacement = placement[0];

  if (enableAdRefresh && Number.isFinite(pageAdReload) && pageAdReload > 0) {
    const index = pageAdReload % placement.length;
    adPlacement = placement[index];
  }
  return {
    adPlacement,
    isNativeAd: !!adPlacement?.body?.trim()
  };
};

export {
  canonicalizeDataString,
  isValidCondition,
  getPageAdsData,
  multiAdsPayload,
  adZonesByDevice,
  getInPageAdsConfig,
  getTargeting,
  getLengthRanges,
  getYearRanges,
  getDefaultAdAlt,
  getPlacement,
  AD_PAGE_KEY,
  getDefaultAdHref
};

