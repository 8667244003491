export const hrefLangs = {
  'au': 'en-AU',
  'de': 'de',
  'dk': 'da',
  'gr': 'el-GR',
  'en': 'en',
  'es': 'es',
  'fi': 'fi',
  'fr': 'fr',
  'hr': 'hr',
  'it': 'it',
  'nl': 'nl',
  'no': 'no',
  'se': 'sv',
  'pl': 'pl',
  'tr': 'tr',
  'uk': 'en-GB',
  'us': 'en-US',
  'ca': 'en-CA',
  'pt': 'pt'
};

export const htmlLanguage = {
  au: 'en-AU',
  de: 'de-DE',
  dk: 'da-DK',
  es: 'es-ES',
  fi: 'fi-FI',
  fr: 'fr-FR',
  gr: 'el-GR',
  hr: 'hr-HR',
  it: 'it-IT',
  nl: 'nl-NL',
  no: 'nn-NO',
  pl: 'pl-PL',
  se: 'sv-SE',
  tr: 'tr-TR',
  uk: 'en-GB',
  us: 'en-US',
  ca: 'en-CA',
  pt: 'pt-PT',
  br: 'pt-BR'
};

export const languagesEnglish = {
  en: 'english',
  ca: 'english',
  da: 'danish',
  es: 'spanish',
  it: 'italian',
  de: 'german',
  nl: 'dutch',
  no: 'norwegian',
  fi: 'finnish',
  se: 'swedish'
};

export const languages = {
  en: 'English',
  ca: 'English',
  es: 'Español',
  fr: 'Français',
  da: 'Dansk',
  de: 'Deutsch',
  it: 'Italiano',
  nl: 'Nederlands',
  no: 'Norsk',
  fi: 'suomi',
  sv: 'Svenska',
  dk: 'Dansk',
  se: 'Svenska',
  hr: 'Hrvatska',
  gr: 'Ελλάδα',
  pl: 'Polski',
  tr: 'Türkiye',
  us: 'English',
  au: 'English',
  uk: 'English',
  pt: 'Portuguese'
};


// Maping of the languages name to the language code
export const languageCodes = {
  'English': 'en',
  'Español': 'es',
  'Français': 'fr',
  'Dansk': 'da',
  'Deutsch': 'de',
  'Italiano': 'it',
  'Nederlands': 'nl',
  'Norsk': 'no',
  'suomi': 'fi',
  'Svenska': 'sv',
  'Hrvatska': 'hr',
  'Ελλάδα': 'gr',
  'Polski': 'pl',
  'Türkiye': 'tr',
  'Portuguese': 'pt'
};

// Mapping to the routes constants
export const pageTypes = {
  'HOMEPAGE': 'HOME_URL_PATTERN',
  'DEALERSEARCH': 'PARTY_SEARCH_URL_ROOT',
  'TYPE_CLASS': 'TYPE_CLASS_PAGES_ROOT',
  'CLASS': 'CLASS_PAGE_URL_PATTERN',
  'BOATS': 'SEARCH_URL_ROOT',
  'ENGINES': 'ENGINES_SEARCH_URL_ROOT',
  'HEALTHCHECK': 'HEALTHCHECK_URL_PATTERN',
  'BRANDED_SEARCH': 'BRANDED_SEARCH_URL_ROOT',
  'BRANDED_OEM_SEARCH': 'BRANDED_OEM_SEARCH_URL_ROOT',
  'MOORINGS_SEARCH': 'MOORINGS_SEARCH_URL_ROOT',
  'ENGINES_BRANDED_SEARCH': 'ENGINES_BRANDED_SEARCH_URL_ROOT',
  'DEALER_GALLERY': 'DEALER_SEARCH_URL_ROOT',
  'ENGINES_DEALER_GALLERY': 'ENGINES_DEALER_SEARCH_URL_ROOT',
  'BOAT_DETAILS': 'BOAT_DETAILS_ROOT',
  'ENGINE_DETAILS': 'ENGINE_DETAILS_ROOT',
  'BLOG': 'BLOG_URL_ROOT'
};

export const countries = {
  US: 'United States',
  CA: 'Canada',
  ES: 'España',
  FR: 'France',
  DK: 'Danmark',
  DE: 'Deutschland',
  IT: 'Italia',
  NL: 'Nederland',
  NO: 'Norge',
  FI: 'Suomi',
  SE: 'Sverige',
  UK: 'United Kingdom',
  HR: 'Hrvatski',
  GR: 'Ελληνικά',
  PL: 'Polska',
  TR: 'Türk',
  AU: 'Australia',
  PT: 'Portugal',
  BR: 'Brazil'
};

export const localeTranslations = {
  'en-US': 'us',
  'es-ES': 'es',
  'fr-FR': 'fr',
  'da-DK': 'dk',
  'de-DE': 'de',
  'it-IT': 'it',
  'nl-NL': 'nl',
  'no-NO': 'no',
  'fi-FI': 'fi',
  'se-SE': 'se',
  'en-GB': 'en',
  'en-UK': 'uk',
  'hr-HR': 'hr',
  'el-GR': 'gr',
  'pl-PL': 'pl',
  'tr-TR': 'tr',
  'en-AU': 'au',
  'en-CA': 'ca',
  'pt-PT': 'pt',
  'pt-BR': 'br'
};

export const appLanguageCodes = {
  'en-US': 'en',
  'en-CA': 'ca',
  'es-ES': 'es',
  'fr-FR': 'fr',
  'da-DK': 'dk',
  'de-DE': 'de',
  'it-IT': 'it',
  'nl-NL': 'nl',
  'nb-NO': 'no',
  'fi-FI': 'fi',
  'sv-SE': 'se',
  'en-GB': 'uk',
  'en-UK': 'uk',
  'hr-HR': 'hr',
  'el-GR': 'gr',
  'pl-PL': 'pl',
  'tr-TR': 'tr',
  'en-AU': 'au',
  'pt-PT': 'pt',
  'pt-BR': 'pt'
};
