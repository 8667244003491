import { getCurrentLocale } from './language';
import { isBoatDetailsPageRedesign } from './newBDPHelper';
import { AD_PAGE_KEY, adZonesByDevice} from './ads/adsUtils';
import { detectDevice } from '../tppServices/crossEnvHelpers';
import { getBoatUrl } from './urlHelpers/boat';
import { showRelatedBoatArticles, showVideos, showWordsmithContent } from '../store/utils';
import { shouldAddOEMDetails } from './multiFacetHelper';
import { shouldForceCities } from './locationHelper';
import { asArray, definedValue, itExists, objectClone, truthyOrNull, validKey, valueOrNull } from './index';

const FACETS = 'countrySubdivision,make,condition,makeModel,type,class,country,countryRegion,countryCity,fuelType,hullMaterial,hullShape,minYear,maxYear,minMaxPercentilPrices,enginesConfiguration,enginesDriveType,numberOfEngines';
const FIELDS = 'id,make,model,year,featureType,specifications.dimensions.lengths.nominal,location.address,aliases,owner.logos,owner.name,owner.rootName,owner.location.address.city,owner.location.address.country,price.hidden,price.type.amount,portalLink,class,media,isOemModel,isCurrentModel,attributes,previousPrice,mediaCount,cpybLogo';
const DEFAULT_PAGE_SIZE = 28;
const DEFAULT_SPONSORED_SIZE = 3;

const addHrefToBoatsList = (list) => {
  return list.map((l) => ({ ...l, href: getBoatUrl(l) }));
};

const prepareBoatDataListing = (response) => {
  let listing = response.data;

  if (Array.isArray(listing.dealerListings)) {
    listing.dealerListings = addHrefToBoatsList(listing.dealerListings);
  }
  const similarBoatsRecords = listing?.similarBoats?.records?.records;
  if (Array.isArray(similarBoatsRecords)) {
    listing.similarBoats.records.records = addHrefToBoatsList(similarBoatsRecords);
  }
  return listing;
};

const prepareBoatsData = (response) => {
  const data = response.data;
  /* istanbul ignore next */
  if (!data.facets) {
    data.facets = {};
  }
  const multiAdsData = data.adsData || {};
  return {...data, adsData: multiAdsData};
};

const parseBoatDataError = (error) => {
  const err = error || {};
  let statusCode = err.statusCode || err.response?.status || err.status || 500;
  statusCode = isNaN(statusCode) ? 301 : statusCode;
  const errorMessage = err.message || null;
  const data = err.data || err;
  return { statusCode, errorMessage, data };
};

/**
 * Query object builder.
 * It will only add params if they are defined.
 * If you do:
 * objectQueryBuilder({ a: 'b' })
 * .addParam('c', 'd')
 * .addParam('e', null)
 * .addParam('f', '').build();
 * it will return { a: 'b', c: 'd' }
 * It will only return existing params.
 * @param baseObject original object
 * @returns {object}
 */
const objectQueryBuilder = (baseObject) => {
  let query = baseObject || {};
  const queryBuilder = {
    addParam: (key, value) => {
      if (validKey(key) && validKey(value)) {
        query[key] = value;
      }
      return queryBuilder;
    },
    append(appendable) {
      query = { ...query, ...appendable };
      return queryBuilder;
    },
    // shallow copy good enough
    queryObject: () => {
      return {...query};
    },
    query: () => {
      return Object.keys(query).reduce((acc, key) => {
        if (itExists(query[key])) {
          acc[key] = query[key];
        }
        return acc;
      }, {});
    },
    build: () => {
      query = queryBuilder.query();
      return objectClone(query);
    }
  };
  return queryBuilder;
};

/**
 * Query string builder.
 * It will only add params if they are defined.
 * if you do:
 * queryStringBuilder('/boats')
 *  .addParam('merluza', 'a_la_romana')
 *  .addParam('mero', null)
 *  .addParam('bacalao', '').join();
 *  it will return '/boats?merluza=a_la_romana'
 * @param baseString original string
 * @returns {string}
 */
const queryStringBuilder = (baseString) => {
  const base = baseString || '';
  const params = [];
  const stringBuilder = {
    addParam: (key, value) => {
      if (validKey(value)) {
        params.push(`${key}=${value}`);
      }
      return stringBuilder;
    },
    join: (baseJoin = '?', queryJoin = '&') => {
      return params.length ? base + baseJoin + params.join(queryJoin) : '';
    }
  };
  return stringBuilder;
};

const filterBoatsFacets = (config, urlParams, cleanedUrlParams) => {
  let boatFacets = FACETS;
  const hasMultifaceted = urlParams.multiFacetedMakeModel || urlParams.multiFacetedBoatTypeClass;
  const oneCleanedParams = Object.keys(cleanedUrlParams).length === 1;
  const oneExtraMultiFacetParam = oneCleanedParams && hasMultifaceted;
  const isDynamicContentSupported = definedValue(config?.supports?.dynamicContent, true);
  const cityPostalCode = urlParams?.city?.split(',').length === 1;
  if (isDynamicContentSupported) {
    boatFacets += ',minTotalHorsepowerPercentil,maxTotalHorsepowerPercentil,minLengthPercentil,maxLengthPercentil';
  }
  if (cityPostalCode) {
    boatFacets += ',cityPostalCode';
  }
  if (oneExtraMultiFacetParam) {
    boatFacets += ',minMaxPercentilPrices,minYear,maxYear,hullShape,enginesConfiguration,enginesDriveType,fuelType';
    if (isDynamicContentSupported) {
      boatFacets += ',activities,minTotalHorsepowerPercentil,maxTotalHorsepowerPercentil,minLengthPercentil,maxLengthPercentil';
    }
  }
  if (isDynamicContentSupported && oneCleanedParams && urlParams.multiFacetedBoatTypeClass) {
    boatFacets += ',avgTotalHorsepowerPercentil';
  }

  return boatFacets;
};

const boatsQueryFlags = (config, urlParams) => {
  const supports = config.supports;
  const enableSponsoredSearch = supports?.enableSponsoredSearch ?? true;
  const enableOEM = supports?.enableOEM && urlParams.enableOEM;
  const supportsExtendedSeoContent = !!supports?.extendedSeoContent;
  const enableSponsoredSearchExactMatch = truthyOrNull(supports?.enableSponsoredSearchExactMatch);
  const enableRandomizedSponsoredBoatsSearch = truthyOrNull(supports?.enableRandomizedSponsoredBoatsSearch);
  const sponsoredBoatsSize = supports?.randomSponsoredBoatsSize || DEFAULT_SPONSORED_SIZE;
  let randomSponsoredBoatsSize = enableRandomizedSponsoredBoatsSearch ? sponsoredBoatsSize : null;
  return { enableSponsoredSearch, enableOEM, supportsExtendedSeoContent, enableSponsoredSearchExactMatch, enableRandomizedSponsoredBoatsSearch, randomSponsoredBoatsSize };
};

const filterSeoParams = (seoUrlParams, supportsExtendedSeoContent) => {
  let seoParams = {};
  if (supportsExtendedSeoContent) {
    seoParams = {
      wordsmithContentType: showWordsmithContent(seoUrlParams),
      relatedBoatArticles: showRelatedBoatArticles(seoUrlParams),
      videoType: showVideos(seoUrlParams)
    };
  }
  return seoParams;
};

const cleanedUrlParams = (urlParams) => {
  return Object.fromEntries(
    Object.entries(urlParams).filter(([key]) =>
      !['distance', 'page', 'pageSize', 'sort'].includes(key)
    )
  );
};

const filterOemDetails = (query) => truthyOrNull(shouldAddOEMDetails(query));
const filterForceCities = (query) => truthyOrNull(shouldForceCities(query));
const filterUom = (cookies) => valueOrNull(cookies?.uom);

const filterPageSize = (config) => {
  return config.pages?.searchResults?.pagination?.pageSize || DEFAULT_PAGE_SIZE;
};

const serizalizeZoneIds = (adZones, device) => {
  const filteredZones = adZonesByDevice(adZones, device);
  return Object.values(filteredZones).map(zone => `${zone.zoneID}-${zone.groupID}`).join(',');
};

const getBoatAdsQuery = (config, device, targetedAdsConsent, pageKey) => {
  try {
    const adsConfig = config?.pages?.[pageKey]?.newAdsConfig;
    if (!adsConfig) {
      return {};
    }
    const adZones = adsConfig.adZones || {};
    const zoneIds = serizalizeZoneIds(adZones, device);
    const kwArray = asArray(adsConfig?.kwArray).join('|');
    const hasTargetedAdsConsent = !!targetedAdsConsent || null;
    const adsPerZone = adsConfig?.adsPerZone || 'one';
    return { zoneIds, kwArray, hasTargetedAdsConsent, adsPerZone };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('getBoatAdsQuery error:', error);
  }
  return {};
};

/**
 * Get boats query. Query is complex, splitting by parts can help debugging it.
 * @param config - portal config
 * @param urlParams - url params
 * @param otherParams - I wish I knew what this was, at least we can add here testId and testIP
 * @returns {*}
 */
const getBoatsQuery = (config, urlParams, otherParams, cookies, abContext, device, hasTargetedAdsConsent, srpKey) => {
  const queryBuilder = objectQueryBuilder({});
  const seoUrlParams = cleanedUrlParams(urlParams);
  const adsQuery = getBoatAdsQuery(config, device, hasTargetedAdsConsent, srpKey);
  const {
    enableSponsoredSearch,
    enableOEM,
    supportsExtendedSeoContent,
    enableSponsoredSearchExactMatch,
    enableRandomizedSponsoredBoatsSearch,
    randomSponsoredBoatsSize
  } = boatsQueryFlags(config, urlParams);
  const hasMultifaceted = urlParams.multiFacetedMakeModel ? true : null;
  queryBuilder
    .addParam('page', 1)
    .addParam('facets', filterBoatsFacets(config, urlParams, seoUrlParams))
    .addParam('fields', FIELDS)
    .addParam('useMultiFacetedFacets', true)
    .addParam('exactMakeMatch', hasMultifaceted)
    .addParam('exactModelMatch', hasMultifaceted)
    .addParam('enableSponsoredSearch', enableSponsoredSearch)
    .addParam('enableOEM', enableOEM)
    .addParam('locale', getCurrentLocale(true));

  // append objects to the query
  queryBuilder
    // we should try to know what these are
    .append(otherParams)
    // overwriting stuff :'( page one of the params overwritten
    .append(urlParams)
    .append(filterSeoParams(seoUrlParams, supportsExtendedSeoContent));

  // probably we only need to check url params.
  const tempQuery = queryBuilder.queryObject();

  queryBuilder
    .addParam('pageSize', filterPageSize(config, abContext, cookies))
    .addParam('OEMDetails', filterOemDetails(tempQuery))
    .addParam('uom', filterUom(cookies))
    .addParam('advantageSort', 1)
    .addParam('forceCities', filterForceCities(tempQuery.country))
    // we should remove these and use civilized naming
    .addParam('enableSponsoredSearchExactMatch', enableSponsoredSearchExactMatch)
    .addParam('randomizedSponsoredBoatsSearch', enableRandomizedSponsoredBoatsSearch)
    .addParam('randomSponsoredBoatsSize', randomSponsoredBoatsSize)
    // ads query params
    .addParam('zoneIds', adsQuery.zoneIds)
    .addParam('hasTargetedAdsConsent', adsQuery.hasTargetedAdsConsent)
    .addParam('kwArray', adsQuery.kwArray)
    .addParam('adsPerZone', adsQuery.adsPerZone);

  return queryBuilder.query();
};

const getBoatDetailsQueryString = (id, config, featureFlagNewBDP, targetedAdsConsent) => {
  const queryBuilder = queryStringBuilder(`/boat/${id}`);
  const locale = getCurrentLocale(true);
  const oemRelatedListings = !!config?.supports?.oemRelatedListings || null;
  const isActiveBDPRedesign = featureFlagNewBDP || isBoatDetailsPageRedesign(config);
  const moreFromThisDealerBoatCount = isActiveBDPRedesign ? 8 : null;
  const similarBoats = !!config?.supports?.similarBoats || null;
  const relatedArticles = !!config?.supports?.relatedArticles || null;
  const adsQuery = getBoatAdsQuery(config, detectDevice(), targetedAdsConsent, AD_PAGE_KEY.DETAILS);
  return queryBuilder
    .addParam('otherDealerBoats', true)
    .addParam('locale', locale)
    .addParam('getRelatedListings', oemRelatedListings)
    .addParam('similarBoats', similarBoats)
    .addParam('getRelatedArticles', relatedArticles)
    .addParam('moreFromThisDealerBoatCount', moreFromThisDealerBoatCount)
    .addParam('zoneIds', adsQuery.zoneIds)
    .addParam('hasTargetedAdsConsent', adsQuery.hasTargetedAdsConsent)
    .addParam('kwArray', adsQuery.kwArray)
    .addParam('adsPerZone', adsQuery.adsPerZone)
    .join();
};

export {
  getBoatDetailsQueryString,
  prepareBoatDataListing,
  parseBoatDataError,
  addHrefToBoatsList,
  getBoatsQuery,
  objectQueryBuilder,
  queryStringBuilder,
  prepareBoatsData
};
