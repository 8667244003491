import get from 'lodash/get';

import { getConfig } from './portal';
import { formatBlogCategoriesForUrl } from '../utils/urlHelpers/blog';
import { getMessages } from '../tppServices/translations/messages';

export const getRouteConstants = (intl, addPathnameLocale, config) => {
  const { formatMessage: t } = intl;
  const messages = getMessages();
  // TODO when (if) async, remove this check
  // istanbul ignore next
  if (!config) {
    // TODO when (if) async, remove this call
    config = getConfig();
  }

  const getBlogCategoriesTranslatedName = () => {
    const blogConfig = get(config.pages, 'blog', {});
    const avaliableCategories = get(blogConfig, 'categories', []);
    return avaliableCategories.map(category => t(messages.blog.categories[category.name]));
  };

  const getBlogCategoriesEncodedTranslatedName = () => {
    const blogConfig = get(config.pages, 'blog', {});
    const avaliableCategories = get(blogConfig, 'categories', []);
    return avaliableCategories.map(category => {
      const categoryTranslation = t(messages.blog.categories[category.name]);
      // this can be undefined on tests due to circular dependencies.
      // istanbul ignore next
      return formatBlogCategoriesForUrl ? formatBlogCategoriesForUrl(categoryTranslation) : categoryTranslation;
    });
  };

  return {
    HOME_URL_PATTERN: addPathnameLocale === '' ? '/' : `${addPathnameLocale}/`,
    HOME_URL_ROOT: addPathnameLocale === '' ? '/' : `${addPathnameLocale}/`,
    SEARCH_URL_ROOT: `${addPathnameLocale}/${t(messages.searchRoot)}/`,
    DEALER_SEARCH_URL_ROOT: `${addPathnameLocale}/${t(messages.dealerSearchRoot)}/`,
    SEARCH_URL_ITEM_CATEGORY: t(messages.search.category),
    SEARCH_URL_ITEM_TYPES: t(messages.search.types),
    SEARCH_URL_ITEM_CLASS: t(messages.search.class),
    SEARCH_URL_ITEM_CLASSGROUP: t(messages.search.group),
    SEARCH_URL_ITEM_MAKE: t(messages.search.make),
    SEARCH_URL_ITEM_MODEL: t(messages.search.model),
    SEARCH_URL_ITEM_MODELRANGE: t(messages.search.modelRange),
    SEARCH_URL_ITEM_SUBDIVISION: t(messages.search.subdivision),
    SEARCH_URL_ITEM_SUBDIVISION_US: t(messages.search.subdivisionUS),
    SEARCH_URL_ITEM_SUBDIVISION_CA: t(messages.search.subdivisionCA),
    SEARCH_URL_ITEM_SUBDIVISION_ES: t(messages.search.subdivisionES),
    SEARCH_URL_ITEM_SUBDIVISION_IT: t(messages.search.subdivisionIT),
    SEARCH_URL_ITEM_REGION: t(messages.search.region),
    SEARCH_URL_ITEM_COUNTRY: t(messages.search.country),
    SEARCH_URL_ITEM_CITY: t(messages.search.city),
    SEARCH_URL_ITEM_POSTALCODE: t(messages.search.postalCode),
    SEARCH_URL_ITEM_BY: t(messages.search.by),
    SEARCH_URL_ITEM_RADIUS: t(messages.search.radius),
    SEARCH_URL_ITEM_MAKEMODEL: t(messages.search.makeModel),
    SEARCH_URL_ITEM_FUEL: t(messages.search.fuel),
    SEARCH_URL_ITEM_HULL: t(messages.search.hull),
    SEARCH_URL_ITEM_WORLD_REGION: t(messages.search.worldRegion),
    SEARCH_URL_ITEM_ENGINE: t(messages.search.engine),
    SEARCH_URL_ITEM_ENGINE_NUMBER: t(messages.search.enginesNumber),
    SEARCH_URL_ITEM_ENGINE_HOURS: t(messages.search.engineHours),
    SEARCH_URL_ITEM_LENGTH: t(messages.search.length),
    SEARCH_URL_ITEM_PRICE: t(messages.search.price),
    SEARCH_URL_ITEM_POWER_HP: t(messages.search.powerhp),
    SEARCH_URL_ITEM_YEAR: t(messages.search.year),
    SEARCH_URL_ITEM_CONDITION: t(messages.search.condition),
    SEARCH_URL_ITEM_OWNER: t(messages.search.owner),
    SEARCH_URL_ITEM_KEYWORD: t(messages.search.keyword),
    SEARCH_URL_ITEM_PAGE: t(messages.search.page),
    SEARCH_URL_ITEM_SORT: t(messages.search.sort),
    SEARCH_URL_ITEM_RADIUS_EXACT: t(messages.radiusFacet.exact),
    SEARCH_URL_ITEM_RADIUS_ANY: t(messages.radiusFacet.any),
    SEARCH_URL_ITEM_SALESREP: t(messages.search.salesRep),
    SOCIAL_BROWSE_URL_ROOT: `${addPathnameLocale}/sbp/:id(\\d+)`,
    BOAT_DETAILS_URL_PATTERN: `${addPathnameLocale}/${t(messages.detailsRoot)}/:id([^/]+)`,
    BOAT_DETAILS_ROOT: `${addPathnameLocale}/${t(messages.detailsRoot)}/`,
    ENGINES_SEARCH_URL_ROOT: `${addPathnameLocale}/${t(messages.enginesSearchRoot)}/`,
    ENGINES_DEALER_SEARCH_URL_ROOT: `${addPathnameLocale}/${t(messages.dealerEnginesSearchRoot)}/`,
    ENCODED_ENGINES_SEARCH_URL_ROOT: `${addPathnameLocale}/${encodeURIComponent(t(messages.enginesSearchRoot))}/`,
    ENGINE_DETAILS_URL_PATTERN: `${addPathnameLocale}/${t(messages.detailsEngine)}/:id([^/]+)`,
    ENGINE_DETAILS_ROOT: `${addPathnameLocale}/${t(messages.detailsEngine)}/`,
    SELL_URL_ROOT: `/${t(messages.sellRoot)}/`,
    SELL_ENGINES_URL_ROOT: `/${t(messages.sellEnginesRoot)}/`,
    PARTY_SEARCH_URL_ROOT: `${addPathnameLocale}/${t(messages.partySearchRoot)}/`,
    MEMBERSHIP_URL_ROOT: `${t(messages.membershipRoot)}/`,
    EUROPEAN_MARKETPLACES_URL_ROOT: `${t(messages.europeanMarketplacesRoot)}/`,
    POWER_URL_ROOT: `${addPathnameLocale}/${t(messages.powerRoot)}/`,
    SAIL_URL_ROOT: `${addPathnameLocale}/${t(messages.sailRoot)}/`,
    LIFESTYLE_URL_ROOT: `${addPathnameLocale}/${t(messages.lifestyleRoot)}/`,
    REVIEWS_URL_ROOT: `${addPathnameLocale}/${t(messages.reviewsRoot)}/`,
    BRANDED_SEARCH_URL_ROOT: `${addPathnameLocale}/${t(messages.brandedSearchRoot)}/`,
    BRANDED_OEM_SEARCH_URL_ROOT: `${addPathnameLocale}/${t(messages.brandedOemSearchRoot)}/`,
    MOORINGS_SEARCH_URL_ROOT: `${addPathnameLocale}/${t(messages.mooringsSearchRoot)}/`,
    CUSTOM_BRANDED_SEARCH_URL_ROOT:
      `${addPathnameLocale}/${t(messages.customBrandedSearchRoot)}/` +
      `${encodeURIComponent(t(messages.brandedSearchBroker))}-`,
    ENGINES_BRANDED_SEARCH_URL_ROOT:
      `${addPathnameLocale}/${t(messages.brandedSearchRoot)}-${t(messages.enginesSearchRoot)}/`,
    RESOURCES_URL_ROOT: `${addPathnameLocale}/${t(messages.resourcesRoot)}/`,
    BLOG_URL_ROOT: `${addPathnameLocale}/${t(messages.blogRoot)}/`,
    BLOG_URL_CATEGORIES: getBlogCategoriesTranslatedName().join('|'),
    BLOG_URL_ENCODED_CATEGORIES: getBlogCategoriesEncodedTranslatedName().join('|'),
    BLOG_URL_ITEM_PAGE: t(messages.blog.page),
    PERSONAL_BOAT_SHOPPER_URL_ROOT: `${addPathnameLocale}/${t(messages.personalBoatShopper)}`,
    SERVICES_URL_ROOT: `${addPathnameLocale}/${t(messages.servicesRoot)}`,
    FSBO_URL_ROOT: `${addPathnameLocale}/${t(messages.fsboRoot)}`,
    BOAT_DEALERS_URL_ROOT: `${addPathnameLocale}/${t(messages.boatDealers)}`,
    INSURANCE_URL_ROOT: `${addPathnameLocale}/${t(messages.insurance)}/`,
    BOAT_TRANSPORT_URL_ROOT: `${addPathnameLocale}/${t(messages.boatTransport)}/`,
    DIRECTORY_URL_ROOT: `${addPathnameLocale}/${t(messages.directoryRoot)}`,
    HEALTHCHECK_URL_PATTERN: `${addPathnameLocale}/healthcheck/`,
    DEALER: encodeURIComponent(t(messages.dealerPrefix)),
    TYPE_CLASS_PAGES_ROOT: `${addPathnameLocale}/${t(messages.typeClassPagesRoot)}/`,
    CLASS_PAGE_URL_PATTERN: `${addPathnameLocale}/${t(messages.typeClassPagesRoot)}/:typeClassPage([^/]+)/`,
    BOAT_LOANS_URL_ROOT: `${addPathnameLocale}/${t(messages.boatLoans)}/`,
    YACHT_LOANS_CALCULATOR_URL_ROOT: `${addPathnameLocale}/${t(messages.yachtLoansCalculator)}/`,
  };
};
